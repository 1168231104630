import { gql } from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * The AWSDateTime scalar type provided by AWS AppSync, represents a valid extended ISO 8601 DateTime string. In other
   * words, this scalar type accepts datetime strings of the form YYYY-MM-DDThh:mm:ss.SSSZ. The scalar can also accept
   * "negative years" of the form -YYYY which correspond to years before 0000. For example, "-2017-01-01T00:00Z" and
   * "-9999-01-01T00:00Z" are both valid datetime strings. The field after the two digit seconds field is a nanoseconds f
   * ield. It can accept between 1 and 9 digits. So, for example, "1970-01-01T12:00:00.2Z", "1970-01-01T12:00:00.277Z" and
   * "1970-01-01T12:00:00.123456789Z" are all valid datetime strings. The seconds and nanoseconds fields are optional
   * (the seconds field must be specified if the nanoseconds field is to be used). The time zone offset is compulsory for
   * this scalar. The time zone offset must either be Z (representing the UTC time zone) or be in the format ±hh:mm:ss.
   * The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard.
   */
  AWSDateTime: { input: any; output: any; }
  /**
   * The AWSJSON scalar type provided by AWS AppSync, represents a JSON string that complies with RFC 8259. Maps like
   * "{\"upvotes\": 10}", lists like "[1,2,3]", and scalar values like "\"AWSJSON example string\"", "1", and "true"
   * are accepted as valid JSON and will automatically be parsed and loaded in the resolver mapping templates as Maps,
   * Lists, or Scalar values rather than as the literal input strings. Invalid JSON strings like "{a: 1}", "{'a': 1}"
   * and "Unquoted string" will throw GraphQL validation errors.
   */
  AWSJSON: { input: any; output: any; }
  AWSPhone: { input: any; output: any; }
  /**
   * The AWSURL scalar type provided by AWS AppSync, represents a valid URL string (Ex: https://www.amazon.com/). The URL
   * may use any scheme and may also be a local URL (Ex: http://localhost/). URLs without schemes like "amazon.com" or
   * "www.amazon.com" are considered invalid. URLs which contain double slashes (two consecutive forward slashes) in
   * their path are also considered invalid.
   */
  AWSURL: { input: any; output: any; }
};

export type Archive = {
  __typename?: 'Archive';
  /** If restore_state=RESTORED this field has ISO formatted timestamp with timezone offset */
  expiration?: Maybe<Scalars['String']['output']>;
  /** Extra archive info which usually requires additional requests to access */
  extra?: Maybe<ExtraArchiveInfo>;
  /** Restoration state of the object if any */
  restore_state: RestoreState;
};

/** Represents a condition in a statement. */
export type Condition = {
  __typename?: 'Condition';
  /** The condition key, such as "input.vtk_template". */
  key: Scalars['String']['output'];
  /** The condition operator, such as "StringLike". */
  operator: Scalars['String']['output'];
  /** The condition value. */
  values: Array<Scalars['String']['output']>;
};

export type CopyFileInput = {
  /** ID of the destination file. Typically expressed as S3 URL */
  dest: Scalars['String']['input'];
  /** ID of the source file. Typically expressed as S3 URL */
  src: Scalars['String']['input'];
};

/** Input type for the createUploadProcess mutation. */
export type CreateUploadTicketInput = {
  /**
   * The unique identifier for the new folder, expressed as a URL.
   * Example: s3://my-bucket/folder/new-folder
   */
  folder_id: Scalars['ID']['input'];
  /** Message for the uploader */
  message: Scalars['String']['input'];
};

export type DeleteInput = {
  deletionTime?: InputMaybe<DeletionTime>;
  /** ID of the file or folder to be deleted. E.g. s3://bucket/... */
  id: Scalars['ID']['input'];
};

export enum DeletionTime {
  InThreeDays = 'IN_THREE_DAYS',
  Now = 'NOW'
}

export type DownloadUrlInput = {
  /** ID of the file. E.g. s3://bucket/path/filename.mp4 */
  file_id: Scalars['ID']['input'];
};

export type DownloadUrlPayload = {
  __typename?: 'DownloadUrlPayload';
  signed_url: Scalars['String']['output'];
};

export type ExtraArchiveInfo = {
  __typename?: 'ExtraArchiveInfo';
  restore_eta?: Maybe<Scalars['String']['output']>;
  restore_tier?: Maybe<RestoreTier>;
};

export type File = {
  __typename?: 'File';
  /** It's set for archived objects regardless where those are currently restored or not. otherwise it's not set. */
  archive?: Maybe<Archive>;
  /** If true the file is in long term storage and cannot be accessed immediately, else it is usable without taking any further action. */
  archived: Scalars['Boolean']['output'];
  /** True if deleted but not yet purged from storage. */
  deleted: Scalars['Boolean']['output'];
  /** Contains the extra stream-specific information. */
  extra_info?: Maybe<Scalars['AWSJSON']['output']>;
  /** Contains the streams property of the file's ffprobe output. */
  ffprobe?: Maybe<Array<Scalars['AWSJSON']['output']>>;
  /** ID of the file. E.g. s3://bucket/path/filename.mp4 */
  id: Scalars['ID']['output'];
  /** Date of last modification in ISO 8601 format. This can be considered to be the upload date/time. */
  last_modified: Scalars['String']['output'];
  /** Contains the mediainfo's textual output. */
  mediainfo?: Maybe<Scalars['String']['output']>;
  /** Contains the mediainfo's JSON representation of the file. */
  mediainfo_json?: Maybe<Array<Scalars['AWSJSON']['output']>>;
  /** Human-readable name of the file. Typically the last part of the file's ID */
  name: Scalars['String']['output'];
  /** Size of the file in bytes */
  size: Scalars['Float']['output'];
  /** Array of generated still images of the first video track of the file. */
  stills: Array<Maybe<Still>>;
  /** Tags assigned to the file. */
  tags?: Maybe<Array<Tag>>;
  /** Information about the file's tracks. */
  tracks?: Maybe<Array<Track>>;
  /** Contains the streams warnings - output of basic qc process. */
  warnings?: Maybe<Array<Scalars['AWSJSON']['output']>>;
};

export type FileInput = {
  /** ID of the file. E.g. s3://bucket/path/filename.mp4 */
  file_id: Scalars['ID']['input'];
};

export type Folder = {
  __typename?: 'Folder';
  /** A list of all files within this folder. The `show_deleted` argument can be set to `true` to include deleted files that have not been purged from storage. */
  files: Array<File>;
  /** A list of all sub-folders within this folder. */
  folders: Array<Folder>;
  /** The unique ID of the folder, expressed as a S3 URL, e.g. s3://bucket/path. */
  id: Scalars['ID']['output'];
  /** A human-readable name for the folder, typically the last part of the folder's ID or short name of the bucket. */
  name: Scalars['String']['output'];
  /** A list of this folder's parent folders. */
  parents: Array<Folder>;
  /** A list of tags assigned to this folder. */
  tags?: Maybe<Array<Tag>>;
};


export type FolderFilesArgs = {
  show_deleted?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Input type for the `previewGenerateToken` mutation, used to generate a token for accessing preview files. */
export type GeneratePreviewTokenInput = {
  /** The `asset_id` of the preview file set, as returned in the `data` property of the `previewGenerate` process. */
  asset_id?: InputMaybe<Scalars['String']['input']>;
};

export type Hit = {
  __typename?: 'Hit';
  SequenceNumber: Scalars['String']['output'];
  brefix: Scalars['String']['output'];
  extra_info?: Maybe<Scalars['AWSJSON']['output']>;
  ffprobe?: Maybe<Scalars['AWSJSON']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  mediainfo?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
  tracks?: Maybe<Array<Track>>;
  warnings?: Maybe<Scalars['AWSJSON']['output']>;
};

export type IdSearchResultId = {
  __typename?: 'IdSearchResultId';
  domain?: Maybe<Scalars['String']['output']>;
  relation?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type MetadataSearchResult = {
  __typename?: 'MetadataSearchResult';
  hits?: Maybe<Array<Hit>>;
};

/** The input for the `mkdir` mutation, used to create a new folder in the underlying storage system. */
export type MkdirInput = {
  /**
   * The unique identifier for the new folder, expressed as a URL.
   * Example: s3://my-bucket/folder/new-folder
   */
  folder_id: Scalars['ID']['input'];
};

/** The return value of the mkdir permission, indicating whether a folder was created or not. */
export type MkdirPayload = {
  __typename?: 'MkdirPayload';
  /** A boolean indicating whether the folder was actually created (true) or already existed (false). */
  created?: Maybe<Scalars['Boolean']['output']>;
  /** The parent folder of the newly created folder, which can be used to update the client-side state. */
  parent: Folder;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Trigger a copy process of a file to a new location. */
  copy_file: RepositoryProcessPayload;
  /** Creates a new upload ticket for a folder. The ticket can be used to upload files to the folder using the AWS S3 multipart upload API. */
  createUploadTicket: UploadTicketPayload;
  /** Delete a file. */
  delete: UpdateFilePayload;
  /** Generates a short lived download URL for eligable content */
  generateDownloadUrl: DownloadUrlPayload;
  /** Trigger generation of still images of the first video track of the file. */
  generate_stills: RepositoryProcessPayload;
  /** Creates a directory. */
  mkdir: MkdirPayload;
  /** Trigger a move process of a file to a new location. */
  move_file: RepositoryProcessPayload;
  /** The previewGenerate process does generate the actual preview. */
  previewGenerate: RepositoryProcessPayload;
  /** Generates a short lived DRMtoday upfront auth token from an existing playback allowance generated by a previewGenerate Mutation. */
  previewGenerateToken: PreviewTokenPayload;
  /** The previewPrepare process performs preparatory steps to generate a preview for a folder. */
  previewPrepare: RepositoryProcessPayload;
  /** Internal: Publishes that a Process has been completed to message its subscriptions. */
  publishProcessComplete: RepositoryProcessPayload;
  /** Trigger a refresh of the basic QC of the file. */
  refreshBasicQc: UpdateFilePayload;
  /** Trigger a refresh of the extra data. */
  refreshExtra: UpdateFilePayload;
  /** Trigger a refresh of the ffprobe data. */
  refreshFFProbe: UpdateFilePayload;
  /** Trigger a refresh of the mediainfo and mediainfo_json properties. */
  refreshMediaInfo: UpdateFilePayload;
  /** Trigger a refresh of the tracks property. */
  refreshTracks: UpdateFilePayload;
  /** Registers a webhook to the process. */
  registerWebhook: RepositoryProcessPayload;
  /** Trigger restore of an archived file. */
  restore: RepositoryProcessPayload;
  /** Apply tags to a folder. */
  tag_folder: TagFolderPayload;
  /** Undelete a file. */
  undelete: UpdateFilePayload;
  /** Use to update mutable file info. */
  updateFile: UpdateFilePayload;
};


export type MutationCopy_FileArgs = {
  input: CopyFileInput;
};


export type MutationCreateUploadTicketArgs = {
  input: CreateUploadTicketInput;
};


export type MutationDeleteArgs = {
  input?: InputMaybe<DeleteInput>;
};


export type MutationGenerateDownloadUrlArgs = {
  input: DownloadUrlInput;
};


export type MutationGenerate_StillsArgs = {
  input: RefreshInput;
};


export type MutationMkdirArgs = {
  input: MkdirInput;
};


export type MutationMove_FileArgs = {
  input: CopyFileInput;
};


export type MutationPreviewGenerateArgs = {
  input: PreviewGenerateInput;
};


export type MutationPreviewGenerateTokenArgs = {
  input: GeneratePreviewTokenInput;
};


export type MutationPreviewPrepareArgs = {
  input: PreviewPrepareInput;
};


export type MutationPublishProcessCompleteArgs = {
  result: ProcessInput;
};


export type MutationRefreshBasicQcArgs = {
  input: RefreshInput;
};


export type MutationRefreshExtraArgs = {
  input: RefreshInput;
};


export type MutationRefreshFfProbeArgs = {
  input: RefreshInput;
};


export type MutationRefreshMediaInfoArgs = {
  input: RefreshInput;
};


export type MutationRefreshTracksArgs = {
  input: RefreshInput;
};


export type MutationRegisterWebhookArgs = {
  input: RegisterWebhookInput;
};


export type MutationRestoreArgs = {
  input: RestoreFileInput;
};


export type MutationTag_FolderArgs = {
  input?: InputMaybe<TagFolderInput>;
};


export type MutationUndeleteArgs = {
  input: FileInput;
};


export type MutationUpdateFileArgs = {
  input: UpdateFileInput;
};

/** Represents an organization. */
export type Organization = {
  __typename?: 'Organization';
  /** The unique ID of the organization. */
  id: Scalars['ID']['output'];
  /** The legacy/api name of the organization. */
  legacy_name: Scalars['String']['output'];
  /** The name of the organization. */
  name: Scalars['String']['output'];
};

/** The list of organizations the user is a member of. */
export type OrganizationPayload = {
  __typename?: 'OrganizationPayload';
  /** The organization document in JSON format. */
  organizations: Array<Organization>;
};

/** Represents a policy in the system. */
export type Policy = {
  __typename?: 'Policy';
  /** The display name of the policy. */
  display_name: Scalars['String']['output'];
  /** The statements that make up the policy. */
  statements: Array<Statement>;
};

/** Represents the payload/return value of a permission request. */
export type PolicyPayload = {
  __typename?: 'PolicyPayload';
  /** The permission document in JSON format. */
  licensedServices: Array<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  policies: Array<Policy>;
};

/** Input type to the `previewGenerate` mutation. */
export type PreviewGenerateInput = {
  /**
   * The unique identifier for the new folder, expressed as a URL.
   * Example: s3://my-bucket/folder/new-folder
   */
  folder_id: Scalars['ID']['input'];
};

/** Input type to the `previewPrepare` mutation. */
export type PreviewPrepareInput = {
  /**
   * The unique identifier for the new folder, expressed as a URL.
   * Example: s3://my-bucket/folder/new-folder
   */
  folder_id: Scalars['ID']['input'];
};

/** The PreviewTokenPayload entity holds all the necessary information for playing a preview. */
export type PreviewTokenPayload = {
  __typename?: 'PreviewTokenPayload';
  /** The access token provides authorization for accessing the encrypted DASH or HLS files and their respective manifests. Reference: Token-based Access Control for DASH (TAC). */
  access_token?: Maybe<Scalars['String']['output']>;
  /** The unique identifier for the preview's asset, as specified in the previewGenerateToken mutation. */
  asset_id: Scalars['String']['output'];
  /** The HTTPS URL for the DASH manifest, used for playback. */
  dash_url?: Maybe<Scalars['String']['output']>;
  /** The DRMtoday upfront token authorizes the delivery of the required license for playback. */
  drmtoday_token?: Maybe<Scalars['String']['output']>;
  /** The HTTPS URL for the HLS manifest, used for playback. */
  hls_url?: Maybe<Scalars['String']['output']>;
};

/** Represents a long running action performed by the system. */
export type Process = {
  __typename?: 'Process';
  /** The type of action being performed by the process. */
  action: Scalars['String']['output'];
  /** Additional information related to the action performed by the process. This field is typically only set when the process is in a terminal state. */
  data?: Maybe<Scalars['String']['output']>;
  /** The date and time the process ended. */
  end_date?: Maybe<Scalars['AWSDateTime']['output']>;
  /** A unique identifier for the process. */
  id: Scalars['ID']['output'];
  /** A message indicating the progress of the process. This can be used to provide updates on the process' status to the client. */
  message?: Maybe<Scalars['String']['output']>;
  /** The date and time the process was created. */
  start_date?: Maybe<Scalars['AWSDateTime']['output']>;
  /** The current state of the process. */
  state: ProcessStateEnum;
};

/** Input type used to update a process subscription. */
export type ProcessInput = {
  /** Type of action being performed by the process. */
  action?: InputMaybe<Scalars['String']['input']>;
  /** Optional additional information related to the action performed by the process. Typically set only when the process is in a terminal state. */
  data?: InputMaybe<Scalars['String']['input']>;
  /** Unique identifier for the process. */
  id: Scalars['ID']['input'];
  /** Progress message to provide updates on the process status to the client. */
  message?: InputMaybe<Scalars['String']['input']>;
  /** Current state of the process. */
  state: ProcessStateEnum;
};

/** Enumeration of possible states of a process. */
export enum ProcessStateEnum {
  /** The process has failed. The `message` and/or `data` property contains further details. */
  Failed = 'FAILED',
  /** The process is still running. The  `message` field contains information about the current state. */
  InProgress = 'IN_PROGRESS',
  /** The process finished successfully. If the process has a result, it will be available in the `data` field. */
  Success = 'SUCCESS'
}

export type Query = {
  __typename?: 'Query';
  /** Retrieves information about a specific file, identified by its `id`. */
  file?: Maybe<File>;
  /** Retrieves information about a folder, identified by its `id`. Use `id` value 's3://' to retrieve information about all available buckets. */
  folder?: Maybe<Folder>;
  /** Returns a list of organizations the user is a member of. */
  organizations?: Maybe<OrganizationPayload>;
  /** Returns a PermissionPayload document for the user requesting it. This information can be used to determine which actions should be displayed or hidden in a user interface. */
  policies?: Maybe<PolicyPayload>;
  /** Retrieves information about a specific process, identified by its `id`. */
  process?: Maybe<RepositoryProcessPayload>;
  roots: Array<Folder>;
  /**
   * DEPRECATED: Returns a list of SearchResult objects for a given SearchInput object.
   * @deprecated Field no longer supported
   */
  search?: Maybe<Array<Maybe<SearchResult>>>;
  /**
   * DEPRECATED: Returns a list of SearchResult objects for a specified `id` and `relation`.
   * @deprecated Field no longer supported
   */
  search_ids?: Maybe<Array<Maybe<SearchResult>>>;
  /**
   * DEPRECATED: Returns a MetadataSearchResult for a search term.
   * @deprecated Field no longer supported
   */
  search_metadata?: Maybe<MetadataSearchResult>;
  userProfile?: Maybe<UserProfilePayload>;
};


export type QueryFileArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFolderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProcessArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySearchArgs = {
  input?: InputMaybe<SearchInput>;
};


export type QuerySearch_IdsArgs = {
  id: Scalars['String']['input'];
  relation?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearch_MetadataArgs = {
  search_term: Scalars['String']['input'];
};

export type RefreshInput = {
  /** ID of the file. E.g. s3://bucket/path/filename.mp4 */
  file_id: Scalars['ID']['input'];
  /** When force is true the refresh will be performed disregarding any existing values. */
  force?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RegisterWebhookInput = {
  process_id: Scalars['ID']['input'];
  webhook_url: Scalars['AWSURL']['input'];
};

export type RepositoryProcessPayload = {
  __typename?: 'RepositoryProcessPayload';
  /** The type of action being performed by the process. */
  action?: Maybe<Scalars['String']['output']>;
  /** Additional information related to the action performed by the process. This field is typically only set when the process is in a terminal state. */
  data?: Maybe<Scalars['String']['output']>;
  /** The date and time the process ended. */
  end_date?: Maybe<Scalars['AWSDateTime']['output']>;
  /** A unique identifier for the process. */
  id: Scalars['ID']['output'];
  /** A message indicating the progress of the process. This can be used to provide updates on the process' status to the client. */
  message?: Maybe<Scalars['String']['output']>;
  /** The date and time the process was created. */
  start_date?: Maybe<Scalars['AWSDateTime']['output']>;
  /** The current state of the process. */
  state: ProcessStateEnum;
};

/** Input type for the `restore` Mutation. */
export type RestoreFileInput = {
  days?: InputMaybe<Scalars['Int']['input']>;
  /** A list of file IDs to be restored */
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  tier?: InputMaybe<RestoreTier>;
};

export enum RestoreState {
  /** The object is archived, not restored and restoration isn't started */
  Archived = 'ARCHIVED',
  /** The archived object is restored. Expiration can be read from the ArchiveState struct */
  Restored = 'RESTORED',
  /** Restoration is in progress. ETA can be read from the ArchiveState struct */
  Restoring = 'RESTORING'
}

export enum RestoreTier {
  RestoreTier_1 = 'RESTORE_TIER_1',
  RestoreTier_2 = 'RESTORE_TIER_2',
  RestoreTier_3 = 'RESTORE_TIER_3'
}

/** The SearchInput input object is used to execute a search query in the system. It defines the parameters for the search query, such as the starting index of the result set, the size of the result set, and the Elasticsearch query in the form of a JSON document. */
export type SearchInput = {
  /** Specifies the starting index (0-based) of the result set to be returned. */
  from?: InputMaybe<Scalars['Int']['input']>;
  /** The Elasticsearch query to be executed, represented as a JSON document. */
  query_fragment_json: Scalars['AWSJSON']['input'];
  /** Specifies the number of results to be returned in the result set. */
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchResult = {
  __typename?: 'SearchResult';
  ids: Array<IdSearchResultId>;
  location: Scalars['ID']['output'];
  tags: Array<TagSearchResult>;
  timestamp?: Maybe<Scalars['AWSDateTime']['output']>;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

/** Represents a statement in a policy. */
export type Statement = {
  __typename?: 'Statement';
  /** The actions that the statement applies to. */
  actions: Array<Scalars['String']['output']>;
  /** The conditions under which the statement is true. */
  conditions?: Maybe<Array<Condition>>;
  /** The effect of the statement, either "allow" or "deny". */
  effect: Scalars['String']['output'];
  /** The resources that the statement applies to. */
  resources?: Maybe<Array<Scalars['String']['output']>>;
};

/** Represents a single still image. */
export type Still = {
  __typename?: 'Still';
  /** The human-readable name of the file. */
  name: Scalars['String']['output'];
  /** A signed URL to the actual image file. */
  url: Scalars['AWSURL']['output'];
};

/** A Tag represents a key-value pair that is attached to a `File` or `Folder` object. */
export type Tag = {
  __typename?: 'Tag';
  /** The unique identifier for the tag key. */
  key: Scalars['String']['output'];
  /** The corresponding value for the tag key. */
  value: Scalars['String']['output'];
};

/** Input type for the tag_folder mutation. */
export type TagFolderInput = {
  /**
   * The unique identifier for the new folder, expressed as a URL.
   * Example: s3://my-bucket/folder/new-folder
   */
  folder_id: Scalars['ID']['input'];
  /** The tags to be assigned to the folder. */
  tags?: InputMaybe<Array<TagInput>>;
};

/** Represents the payload/return value of the tag_folder mutation. */
export type TagFolderPayload = {
  __typename?: 'TagFolderPayload';
  /** The updated folder object after tagging. */
  folder: Folder;
};

/** Input type for the tag key-value pair. */
export type TagInput = {
  /** The key for the tag. */
  key: Scalars['String']['input'];
  /** The corresponding value for the tag. */
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Represents the search result of a tag. */
export type TagSearchResult = {
  __typename?: 'TagSearchResult';
  /** The name of the tag. */
  name: Scalars['String']['output'];
  /** The value of the tag. */
  value: Scalars['String']['output'];
};

/** Represents a track within a media file. */
export type Track = {
  __typename?: 'Track';
  /** If disabled, it will not be used for any workflow. */
  enabled: Scalars['Boolean']['output'];
  /** The index of the track as defined by the container format. 0 for raw media files. */
  index: Scalars['Int']['output'];
  /** The language of the track, represented using a BCP-47 code. */
  language: Scalars['String']['output'];
  /** The type of the track, either close caption, subtitle, video, audio, or image. Immutable when audio, video, or image. */
  track_type: TrackType;
};

export type TrackInput = {
  enabled: Scalars['Boolean']['input'];
  index: Scalars['Int']['input'];
  language: Scalars['String']['input'];
  track_type: TrackType;
};

export enum TrackType {
  Audio = 'audio',
  Closedcaption = 'closedcaption',
  Data = 'data',
  Image = 'image',
  Subtitle = 'subtitle',
  Video = 'video'
}

/** Input type for `updateFile` mutation. */
export type UpdateFileInput = {
  /** ID of the file. E.g. s3://bucket/path/filename.mp4 */
  file_id: Scalars['ID']['input'];
  tags?: InputMaybe<Array<InputMaybe<TagInput>>>;
  tracks?: InputMaybe<Array<InputMaybe<TrackInput>>>;
};

/** Generic input type for several more specific file update mutations. */
export type UpdateFilePayload = {
  __typename?: 'UpdateFilePayload';
  file: File;
};

export type UploadTicketPayload = {
  __typename?: 'UploadTicketPayload';
  directory: Scalars['String']['output'];
  expiration: Scalars['AWSDateTime']['output'];
  token: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type UserProfilePayload = {
  __typename?: 'UserProfilePayload';
  /** Email address of the user. */
  email: Scalars['String']['output'];
  /** Name of the user. */
  name: Scalars['String']['output'];
  /** Staff yes/no status of the user. */
  staff: Scalars['Boolean']['output'];
};
