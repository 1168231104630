<td style="white-space: nowrap;">
  {{ poItem.po_item_id }}
</td>
<td style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
  <ng-container *ngIf="poItem.workflow_process.state == 'IN_PROGRESS'">
    <ng-container *ngIf="!poItem.checkpoint_content_complete && poItem.input_brefix.length>1">Waiting for files
    </ng-container>
    <ng-container *ngIf="!poItem.checkpoint_content_complete && poItem.input_brefix == '/'">Resolving Identifier
    </ng-container>
    <ng-container *ngIf="poItem.checkpoint_content_complete && !poItem.checkpoint_encodes_done">Encoding...
    </ng-container>
    <ng-container *ngIf="poItem.checkpoint_content_complete && poItem.checkpoint_encodes_done">Encodes Done
    </ng-container>
  </ng-container>

  <ng-container *ngIf="poItem.workflow_process.state == 'FAILED'">
    {{ poItem.workflow_process.message }}
  </ng-container>

  <ng-container *ngIf="poItem.workflow_process.state == 'SUCCESS'">
    Encoded
  </ng-container>


</td>

<td style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">

  <ng-container *ngIf="poItem.publish_process?.state == 'IN_PROGRESS'">
    Publishing...
  </ng-container>

  <ng-container *ngIf="poItem.publish_process?.state == 'FAILED'">
    Error: {{ poItem.publish_process.message }}
  </ng-container>

  <ng-container *ngIf="poItem.publish_process?.state == 'SUCCESS'">
    @switch (poItem.po_destination) {
      @case ("safran-album") {
        Selected
      }
      @default {
        <div>Published</div>
      }
    }
  </ng-container>
</td>
<td>
  <div style="display: flex; flex-wrap: wrap; flex-direction: row; gap: 8px">
    <ng-container *ngFor="let track of poItem.tracks">
      <div *ngIf="track.codec_type == 'video'">
        <span *ngIf="track.source != null" class="alert cl-alert-check" style="white-space: nowrap">&nbsp;</span>
        <span *ngIf="track.source == null && track.messages!=null && track.messages.length>0"
              class="alert cl-alert-warning"
              style="white-space: nowrap">
        &nbsp;</span>
        <span *ngIf="track.source == null && track.messages!=null && track.messages.length==0"
              class="alert cl-alert-info"
              style="white-space: nowrap">
        &nbsp;</span>
      </div>
    </ng-container>
  </div>
</td>
<td>
  <div style="display: flex; flex-wrap: wrap; flex-direction: row; gap: 8px">
    <ng-container *ngFor="let track of poItem.tracks">
      <div *ngIf="track.codec_type == 'audio'">
      <span *ngIf="track.source != null" class="alert cl-alert-check" style="white-space: nowrap">
        <small>{{ track.source.lang || track.lang }}</small></span>
        <span *ngIf="track.source == null && track.messages!=null && track.messages.length>0"
              class="alert cl-alert-warning"
              style="white-space: nowrap">
        <small>{{ track.lang }}</small></span>
        <span *ngIf="track.source == null && track.messages!=null && track.messages.length==0"
              class="alert cl-alert-info"
              style="white-space: nowrap">
        <small>{{ track.lang }}</small></span>
      </div>
    </ng-container>
  </div>
</td>
<td>
  <div style="display: flex; flex-wrap: wrap; flex-direction: row; gap: 8px">
    <ng-container *ngFor="let track of poItem.tracks">
      <div *ngIf="track.codec_type == 'subtitle'">
      <span *ngIf="track.source != null" class="alert cl-alert-check" style="white-space: nowrap">
        <small>{{ track.source.lang || track.lang }}</small></span>
        <span *ngIf="track.source == null && track.messages!=null && track.messages.length>0"
              class="alert cl-alert-warning"
              style="white-space: nowrap">
        <small>{{ track.lang }}</small></span>
        <span *ngIf="track.source == null && track.messages!=null && track.messages.length==0"
              class="alert cl-alert-info"
              style="white-space: nowrap">
        <small>{{ track.lang }}</small></span>
      </div>
    </ng-container>
  </div>
</td>
<td>
  <div style="display: flex; flex-wrap: wrap; flex-direction: row; gap: 8px">
    <ng-container *ngFor="let track of poItem.tracks">
      <div *ngIf="track.codec_type == 'closedcaption'">
        <p *ngIf="track.source != null" class="alert cl-alert-check" style="white-space: nowrap">
          <small>{{ track.source.lang || track.lang }}</small></p>
        <p *ngIf="track.source == null && track.messages!=null && track.messages.length>0"
           class="alert cl-alert-warning"
           style="white-space: nowrap">
          <small>{{ track.lang }}</small></p>
        <p *ngIf="track.source == null && track.messages!=null && track.messages.length==0" class="alert cl-alert-info"
           style="white-space: nowrap">
          <small>{{ track.lang }}</small></p>
      </div>
    </ng-container>
  </div>
</td>
<td>
  <div style="display: flex; flex-wrap: wrap; flex-direction: row; gap: 8px">
    <ng-container *ngFor="let track of poItem.tracks">
      <div *ngIf="track.codec_type == 'image'">
        <span *ngIf="track.source != null" class="alert cl-alert-check" style="white-space: nowrap">
         </span>
        <span *ngIf="track.source == null && track.messages!=null && track.messages.length>0"
              class="alert cl-alert-warning"
              style="white-space: nowrap">
        &nbsp;</span>
        <span *ngIf="track.source == null && track.messages!=null && track.messages.length==0"
              class="alert cl-alert-info"
              style="white-space: nowrap">
        &nbsp;</span>
      </div>
    </ng-container>
  </div>
</td>
<td *ngIf="displayMetadata">
  <div style="display: flex; flex-wrap: wrap; flex-direction: row; gap: 8px"
       *ngIf="poItem.checkpoint_metadata_available != null; else nometdata">
    <div>
        <span *ngIf="!poItem.checkpoint_metadata_available"
              class="alert cl-alert-warning"
              style="white-space: nowrap; overflow:hidden; text-overflow:ellipsis;"> {{ poItem?.metadata_message }}
          &nbsp;</span>

      <span *ngIf="poItem.checkpoint_metadata_available"
            class="alert cl-alert-check"
            style="white-space: nowrap; overflow:hidden; text-overflow:ellipsis;"></span>
    </div>
  </div>
  <ng-template #nometdata>
    <div style="display: flex; flex-wrap: wrap; flex-direction: row; gap: 8px">
      <small style="overflow:hidden; text-overflow:ellipsis;">No metadata required</small>
    </div>
  </ng-template>
</td>
<td class="cl-td-links" style="width: 10%">
  <a class="cl-a-btn " [class.cl-a-spinner]="publish_single_po_inflight"
     (click)="$event.stopPropagation(); this.publish_single_po()"
     *ngIf="(PO_DESTINATIONS[poItem['po_destination']] != null &&  PO_DESTINATIONS[poItem['po_destination']].publishable)">
    Publish
  </a>

  <a class="cl-a-btn cl-a-external"
     [routerLink]="['/reports', this.organization()?.id ,  poItem.po.po_name, poItem.po_item_id]"
     *ngIf="(PO_DESTINATIONS[poItem['po_destination']] != null &&  PO_DESTINATIONS[poItem['po_destination']].publishable) && poItem.publish_process?.state == 'SUCCESS'">
    Delivery Report
  </a>

  <ng-container
    *ngIf="'contentplatform:repository:Mutation/GenerateDownloadUrl' | isAllowed:'contentplatform:folder:input.file_id/s3://' + poItem.output_brefix: organization()">
    <a class="cl-a-btn" (click)="openDlDialog(); $event.stopPropagation()">
      Download
    </a>
  </ng-container>

  <a class="cl-a-btn" [class.cl-a-spinner]="restart_single_po_inflight"
     (click)="$event.stopPropagation(); restartWorkflow()"
     *ngIf="'contentplatform:workflow:Mutation/RestartPoItem' | isAllowed:'contentplatform:po:input.po_name/' + poItem.po.po_name: organization()">
    Restart
  </a>
</td>
<td style="width: 10px;">
  <i role="button" *ngIf="!open" class="fas fa-angle-down" (click)="toggle()"></i>
  <i role="button" *ngIf="open" class="fas fa-angle-up" (click)="toggle()"></i>
</td>
