<header class="print-only" style="width: 100%">
  <div class="header-content">
    <div>
      <h1 class="cl-h1">Delivery Report</h1>
    </div>
    <img src="assets/castlabs-logo.png" alt="Logo" class="logo"/>
  </div>
</header>

<main class="screen-only" style="flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding: 20px;">
  <span *ngIf="groupedReports  === null">We are not yet ready to go.<br/> Once we are ready you can click the button below to print the report!<br/></span>
  <span *ngIf="(groupedReports | keyvalue)?.length == 0">There are no reports!<br/> Not all delivery destination yet support reports<br/></span>
  <span *ngIf="(groupedReports | keyvalue)?.length > 0">Ready to go?<br/> Click the button below to prepare your delivery report for printing.<br/></span>
  <button class="btn cl-btn-primary" style="margin-top: 16px" (click)="printPage()"  [class.cl-btn-spinner]="groupedReports === null"
          [disabled]="(groupedReports | keyvalue)?.length == 0"><span>Print</span> <i class="fas fa-angle-right"></i>
  </button>
</main>

<main class="print-only">

  <div *ngFor="let method of groupedReports | keyvalue">
    <h2 *ngIf="method.key === 's3'">AWS S3 API based deliveries</h2>
    <h2 *ngIf="method.key === 'SmartJog'">SmartJog deliveries</h2>
    <p>The following files have been delivered:</p>
    <table *ngIf="method.key === 's3'">
      <thead>
      <tr>
        <th>Filename</th>
        <th>Folder</th>
        <th>Size</th>
        <th>Upload Date</th>
      </tr>
      </thead>
      <tbody>

      <tr *ngFor="let report of method.value">
        <td>{{ report['filename'] }}</td>
        <td>{{ report['target_location'] }}</td>
        <td>{{ report['size'] }}</td>
        <td>{{ report['completed_at'] | date: 'd MMM y,&nbsp;HH:mm' }}</td>
      </tr>
      </tbody>
    </table>
    <table *ngIf="method.key === 'SmartJog'">
      <thead>
      <tr>
        <th>Recipient</th>
        <th>Location on Receiver<br/>(md5)</th>
        <th>Upload Date<br/>Tracking Number</th>
      </tr>
      </thead>
      <tbody>

      <tr *ngFor="let report of method.value">
        <td>{{ report['recipient'] }}</td>
        <td>{{ report['location_receiver'] }}<br/>({{ report['md5_source'] }})</td>
        <td>{{ report['completed_at'] | date: 'd MMM y,&nbsp;HH:mm' }}<br/>{{ report['tracking_number'] }} </td>
      </tr>
      </tbody>
    </table>
  </div>
</main>
