import {Component, effect} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {ContextService} from '../../services/context.service';
import {PO_ITEM_LIST_DELIVERIES} from '../../queries';
import {Query as WorkflowQuery} from '../../types-workflow';
import {ActivatedRoute} from '@angular/router';
import {DatePipe, JsonPipe, KeyValuePipe, NgForOf, NgIf} from '@angular/common';
import {HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'app-display-delivery-report',
  imports: [
    NgForOf, NgIf,
    KeyValuePipe,
    JsonPipe,
    DatePipe
  ],
  templateUrl: './display-delivery-report.component.html',
  styleUrl: './display-delivery-report.component.scss'
})
export class DisplayDeliveryReportComponent {
  groupedReports: Record<string, any[]> = null;

  constructor(private apollo: Apollo, private contextService: ContextService,
              private route: ActivatedRoute) {

    this.apollo.use('workflow').query<WorkflowQuery>({
      query: PO_ITEM_LIST_DELIVERIES(this.route.snapshot.params['organization_id']),
      context: {
        headers: new HttpHeaders({
          'X-Castlabs-Organization': this.route.snapshot.params['organization_id'],
        })
      },
      variables: {
        'po_name': this.route.snapshot.params['po_name'],
        'po_item_id': this.route.snapshot.params['po_item_id']
      }
    }).subscribe({
      next: res => {
        this.groupedReports = {}
        for (const po of res.data.list_POs.pos) {
          for (const poitem of po.poitems) {
            for (const report of poitem.publish_reports) {
              const parsedReport = JSON.parse(report);
              const method = parsedReport.delivery_method || 'unknown';

              if (!this.groupedReports[method]) {
                this.groupedReports[method] = [];
              }

              this.groupedReports[method].push(parsedReport);
            }
          }
        }
      },
      error: err => {
        alert(err)
      }
    });
  }

  printPage() {
    window.print();
  }
}
