import {Route, Routes, UrlMatcher, UrlMatchResult, UrlSegment, UrlSegmentGroup} from '@angular/router';
import {LoggedInComponent} from './components/logged-in/logged-in.component';
import {OrganizationSwitcherComponent} from './components/organization-select/organization-switcher.component';
import {UserProfileComponent} from './components/user-profile/user-profile.component';
import {OrganizationSelectedComponent} from './components/organization-selected/organization-selected.component';
import {HomeComponent} from './components/s3-browse/home-component/home.component';
import {FileDetailsComponent} from './components/s3-browse/file-details/file-details.component';
import {FolderComponent} from './components/s3-browse/folder/folder.component';
import {SearchPageComponent} from './components/search/search-page.component';
import {AudioOrderComponent} from './components/order-music/audio-order.component';
import {PreviewsComponent} from './components/previews/previews.component';
import {ListPreviewsForAirlineComponent} from './components/previews/list-previews-for-airline/list-previews.component';
import {PreviewPoitemComponent} from './components/previews/display-poitem-preview/preview-poitem.component';
import {WorkflowsComponent} from './components/workflows/workflows.component';
import {ListPosComponent} from './components/workflows/list-pos.component';
import {ListPoItems2Component} from './components/workflows/list-po-items2.component';
import {DisplayDeliveryReportComponent} from './reports/display-delivery-report/display-delivery-report.component';

export const customMatcher: UrlMatcher = (
  segments: UrlSegment[],
  group: UrlSegmentGroup,
  route: Route
): UrlMatchResult => {
  const idPaths = segments.map(segment => segment.path);

  const mergedId = idPaths.join('/');
  const idSegment: UrlSegment = new UrlSegment(mergedId, {assetpath: mergedId});
  return (
    {
      consumed: segments,
      posParams: {assetpath: idSegment}
    });
};

export function prefixWithEncodedSlashMatcher(segments: UrlSegment[]): UrlMatchResult | null {

  if (segments.length === 2 && segments[1].path.endsWith('/')) {
    console.log('HITHITHITHIT');
    return {
      consumed: segments,
      posParams: {
        airline: segments[0],
        poprefix: segments[1],
      },
    };
  }
  return null;
}

export function poWithoutEncodedSlashMatcher(segments: UrlSegment[]): UrlMatchResult | null {
  if (segments.length === 2 && !segments[1].path.endsWith('/')) {
    return {
      consumed: segments,
      posParams: {
        airline: segments[0],
        po: segments[1],
      },
    };
  }
  return null;
}

export const routes: Routes = [
  {
    path: 'reports/:organization_id/:po_name',
    component: DisplayDeliveryReportComponent
  },
  {
    path: 'reports/:organization_id/:po_name/:po_item_id',
    component: DisplayDeliveryReportComponent
  },
  {
    path: '',
    component: LoggedInComponent,
    children: [
      {path: '', pathMatch: 'full', component: OrganizationSwitcherComponent},
      {path: 'userprofile', pathMatch: 'full', component: UserProfileComponent},
      {
        path: 'o/:organization_id',
        component: OrganizationSelectedComponent,
        children: [
          {path: '', pathMatch: 'full', redirectTo: 'files'},
          {
            path: 'files',
            children: [
              {
                path: '', pathMatch: 'full', component: HomeComponent,
              },
              {
                path: 'details', children: [{
                  matcher: customMatcher,
                  children: [
                    {path: '', pathMatch: 'full', component: FileDetailsComponent},
                  ]
                },
                ]
              },
              {
                path: 'browse', children: [{
                  matcher: customMatcher,
                  children: [
                    {path: '', pathMatch: 'full', component: FolderComponent},
                  ]
                },
                ]
              },

            ]
          },
          {path: 'search', pathMatch: 'full', component: SearchPageComponent },
          {path: 'audioorder', pathMatch: 'full', component: AudioOrderComponent},
          {
            path: 'previews', children: [
              {path: '', pathMatch: 'full', component: PreviewsComponent},
              {path: ':airline', pathMatch: 'full', redirectTo: ':airline/' },
              {path: ':airline/:po', pathMatch: 'full', component: ListPreviewsForAirlineComponent},
              {
                path: ':airline/:po/:poitem/preview',
                pathMatch: 'full',
                component: PreviewPoitemComponent,
              },
            ]
          },
          {
            path: 'workflows',
            children: [
              {path: '', pathMatch: 'full', component: WorkflowsComponent},
              {path: ':airline', pathMatch: 'full', component: ListPosComponent},
              {
                matcher: prefixWithEncodedSlashMatcher,
                pathMatch: 'full', component: ListPosComponent
              },
              {
                matcher: poWithoutEncodedSlashMatcher,
                pathMatch: 'full',
                component: ListPoItems2Component
              },
              {
                path: ':airline/:po/:poitem/preview',
                pathMatch: 'full',
                component: PreviewPoitemComponent
              },
            ]
          },
        ]
      },
    ],
  },
  {path: '**', redirectTo: '/'}

];
