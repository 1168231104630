<ng-template #uploadProcess>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">Upload links for PO</div>
      <div class="modal-body">
        <div *ngFor="let u of uploads">
          <a class="cl-a-text cl-a-external" [attr.href]="u.url">{{ u.text }}</a>
        </div>

      </div>
      <div class="modal-footer">
      <span class="modal-footer-left cl-spacing-box cl-spacing-none">
                    </span>
        <span class="modal-footer-center cl-spacing-box cl-spacing-none"></span>
        <span class="modal-footer-right cl-spacing-box cl-spacing-none">
                    <button type="button" class="btn cl-btn-outline-primary it-ok"
                            (click)="this.dialog.closeAll()">
                        <span>Close</span><i class="fas fa-angle-right"></i>
                    </button></span>
      </div>
    </div>
  </div>
</ng-template>


<app-logo-area [breadcrumbs]="breadCrumbs | async"></app-logo-area>
<main>
  <app-headline-section
    [title]="'Workflows'"
  ></app-headline-section>
  <section class="cl-section-regular">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="alert cl-alert-action">
            <a role="button" (click)="genUploads(); this.openDialog(uploadProcess)">
              Generate Upload Links
            </a>
            <a role="button"
               *ngIf="'contentplatform:workflow:Mutation/Publish' | isAllowed: 'contentplatform:poitem:input.po_name/' + this.po_name: organization()"
               (click)="this.publish()">
              Publish PO
            </a>

            <a role="button" class="cl-a-external"
               *ngIf="'contentplatform:workflow:Mutation/Publish' | isAllowed: 'contentplatform:poitem:input.po_name/' + this.po_name: organization()"
               [routerLink]="['/reports', this.organization()?.id ,  this.po_name]">
              Delivery Reports
            </a>


          </p>
        </div>
      </div>
    </div>

  </section>
  <app-filter-section [all]="poitems$ | async" [filtered]="poitems_filtered$ | async" [formGroup]="filters">
    <section>
      <div class="container">
        <form [formGroup]="filters" class="cl-form ">
          <div class="row ">
            <div class="col-12">
              <label for="filter" class="form-label">Filter by title and filename</label>
              <input type="text" style="width: 100%" formControlName="t" id="filter">
              <p class="valid-feedback">Filter terms are case-insensitive.</p>
            </div>
            <div class="col-6">
              <label for="encode_state" class="form-label">Encode State</label>
              <select formControlName="e" class="form-select" id="encode_state">
                <option [value]="''">All</option>
                <option [value]="'IN_PROGRESS'">In progress</option>
                <option [value]="'FAILED'">Failed</option>
                <option [value]="'SUCCESS'">Success</option>
              </select>
            </div>
            <div class="col-6">
              <label for="publish_state" class="form-label">Publish State</label>
              <select formControlName="p" class="form-select" id="publish_state">
                <option [value]="''">All</option>
                <option [value]="'IN_PROGRESS'">In progress</option>
                <option [value]="'FAILED'">Failed</option>
                <option [value]="'SUCCESS'">Success</option>
              </select>
            </div>
          </div>
        </form>
      </div>
    </section>
  </app-filter-section>
  <section class="cl-section-regular" *ngIf="loading()">
    <div style="height: 10vh"></div>
    <div style="width: 100%; display: flex;  align-items: center; justify-content: center;">
      <div class="cl-spinner">Loading PO Items...<i class="fas fa-spinner fa-spin"></i></div>
    </div>
  </section>

  <ng-container *ngIf="(poitems_filtered$ | async) as poitems_filtered  ">
    <section class="cl-section-regular" [hidden]="loading()" *ngIf="(poitems$ | async) as poitems">
      <div class="container" *ngIf="poitems?.length == 0">
        <div class="row">
          <div class="col-12">
            <div style="width: 100%; display: flex;  align-items: center; justify-content: center;">
              <img width="250" src="assets/emptystates/EmptyInbox.png" alt="empty letter sorter"/>
              <div><h2>This PO is empty</h2></div>
            </div>
          </div>

        </div>
      </div>
      <div class="container" *ngIf="poitems?.length > 0 &&  poitems_filtered?.length === 0">
        <div class="row">
          <div class="col-12">
            <div style="width: 100%; display: flex;  align-items: center; justify-content: center;">
              <img width="250" src="assets/emptystates/EmptyInbox.png" alt="empty letter sorter"/>
              <div><h2>The selection is empty</h2></div>
            </div>
          </div>

        </div>
      </div>
      <div class="container" *ngIf="poitems_filtered.length > 0">
        <div class="row">
          <div class="col-12">
            <div class="cl-p-large">{{ (po$ | async).finalized_state }}</div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <table class="cl-table-data" style="table-layout: auto">
              <thead>
              <tr>
                <th class="cl-table-head cl-p-tiny">Title</th>
                <th class="cl-table-head cl-p-tiny">Encode</th>
                <th class="cl-table-head cl-p-tiny">Publish</th>
                <th class="cl-table-head cl-p-tiny">Video</th>
                <th class="cl-table-head cl-p-tiny">Audio</th>
                <th class="cl-table-head cl-p-tiny">Subs</th>
                <th class="cl-table-head cl-p-tiny">CC</th>
                <th class="cl-table-head cl-p-tiny">Images</th>
                <th class="cl-table-head cl-p-tiny" *ngIf="hasMetadata$ | async ">Metadata</th>
                <th class="cl-table-head cl-p-tiny">Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <ng-container *ngIf="prefix$ | async as parent else airlinelink">
                  <td><a [routerLink]="['/o', this.organization()?.id, 'workflows',  iata_code$ | async, parent]">..
                    (up)</a></td>
                </ng-container>
                <ng-template #airlinelink>
                  <td><a [routerLink]="['/o', this.organization()?.id, 'workflows',  iata_code$ | async]">..
                    (up)</a></td>
                </ng-template>

                <td class="cl-table-cel-links"></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td *ngIf="hasMetadata$ | async "></td>
                <td></td>
              </tr>

              <ng-container *ngFor="let poitem of poitems_filtered; let i = index; trackBy: poItemTrackBy">
                <tr app-po-item-line [poItem]="poitem" (toggleDetails)="this.opened.toggle(poitem)"
                    [displayMetadata]="hasMetadata$ | async"></tr>
                <tr style="height: 0"></tr>
                <tr app-po-item-details [poItem]="poitem" [class.hidden]="!this.opened.isSelected(poitem)"
                    [airline]="airline()" [organization]="organization()"></tr>
              </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </ng-container>
</main>
